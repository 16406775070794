import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import OrderConstant from "../../constants/OrderConstant";
import { setLoaderState as setLoaderStateAction } from "../../redux/modules/order";

import {
  getStorageListByIdArray as getStorageListByIdArrayAction,
  getStorageList as getStorageListAction,
  getStorageCategoryList as getStorageCategoryListAction,
  setStorageFilter as setStorageFilterAction,
  setStorageAsc as setStorageAscAction,
  update as updateAction,
} from "../../redux/modules/storage";

import { getCategoryList as getCategoryListAction } from "../../redux/modules/category";

import { formatDateDMY, formatDateHM } from "../../utilities/date";
import { numberWithCommas } from "../../utilities/numbers";
import {
  toArrayClearList,
  inArrayValueByKey,
  unCheckInArray,
} from "../../utilities/array";

import UiButtonColor from "../../components/button/UiButtonColor";
import UiPageText from "../../components/text/UiPageText";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";

import "./HomeStyle.scss";
import UiModalImport from "../../components/modals/UiModalImport";
import UiModalSave from "../../components/modals/UiModalSave";

function HomePage(props) {
  const navigate = useNavigate();
  const [modalImportActive, setModalImportActive] = useState(false);
  const [modalSaveActive, setModalSaveActive] = useState(false);

  const [page, setPage] = useState(0);
  const [maxPages, setMaxPage] = useState(0);

  const [filter, setFilter] = useState([]);
  const [selectedArr, setSelectedArr] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);

  const titlesList = [
    {
      delete: true,
      onDelete: () => {
        if (selectedItem.length == 0) {
          getList(page, [], false, true );
        } else {
          if (props.login.user?.type == 0) {
            if (window.confirm("Удалить элементы ?")) {

                selectedItem.map((item) => {
                  let _item = item;
                  _item.is_del = 1;
                    props.update(_item).then(() =>{  getList(page); });
                });
             
              
            }
          }
        }
      },
    },
    { sort: true, title: "Бренд" },
    { sort: true, title: "Наименование" },
    { sort: false, title: "Фасовка" },
    { sort: true, title: "Артикул" },
    { sort: false, title: "Цена" },
    { sort: true, title: "Страна" },
    { sort: true, title: "Поставщик" },
    { sort: false, title: "Дата обновления" },
    { sort: false, title: "Габариты" },
    { sort: false, title: "Сроки поставки" },
    { sort: false, title: "Комментарий" },
    { sort: false, title: "НДС" },
    { sort: false, title: "" },
  ];

  const [clientsTitles, setClientsTitles] = useState(titlesList);
  const [tableListOrders, setTableListOrders] = useState([]);

  useEffect(() => {
    props.getStorageCategoryList().then((data) => {});
  }, []);

  useEffect(() => {
    setClientsTitles(titlesList);
    props.setLoaderState(true);
    getList(page);
  }, [props.category.selectedCategory, props.login.user]);

  function getList(_page = 1, _filter = [], _asc = false, _allSel = false ) {
    let _selectedArr = [];

    if (_allSel) {
      selectedArr.map((item) => {
        _selectedArr.push(item);
      });
      setSelectedItem(_selectedArr);

 
    }

    props.getCategoryList();
    props
      .getStorageList(_page + 1, props.category.selectedCategory, _asc, _filter)
      .then((list) => {
        let arr = [];
        setPage(_page);
        setMaxPage(Math.ceil(list.total / 50));
        list.data.map((item) => {
          let _itemArr = [
            {
              button:
                selectedItem.filter((it) => {
                  return it.id == item.id;
                }).length > 0 ||
                _selectedArr.filter((it) => {
                  return it.id == item.id;
                }).length > 0
                  ? "save"
                  : "check-empty",
              onClick: () => {
                let arr = selectedItem;
                console.log(arr);
                setSelectedItem(unCheckInArray(arr, item));
                getList(page);
              },
            },

            {
              input: `${item.brand}`,
              onBlurInput: (v) => {
                let _item = item;
                _item.brand = v;
                props.update(_item).then(() => getList(page));
              },
            },
            {
              input: `${item.title}`,
              onBlurInput: (v) => {
                let _item = item;
                _item.title = v;
                props.update(_item).then(() => getList(page));
              },
            },
            {
              input: `${item.packaging}`,
              onBlurInput: (v) => {
                let _item = item;
                _item.packaging = v;
                props.update(_item).then(() => getList(page));
              },
            },
            {
              input: `${item.article}`,
              onBlurInput: (v) => {
                let _item = item;
                _item.article = v;
                props.update(_item).then(() => getList(page));
              },
            },

            {
              input: `${item.price}`,
              onBlurInput: (v) => {
                let _item = item;
                _item.price = v;
                props.update(_item).then(() => getList(page));
              },
            },
            {
              input: `${item.country}`,
              onBlurInput: (v) => {
                let _item = item;
                _item.country = v;
                props.update(_item).then(() => getList(page));
              },
            },
            {
              input: `${item.provider}`,
              onBlurInput: (v) => {
                let _item = item;
                _item.provider = v;
                props.update(_item).then(() => getList(page));
              },
            },
            {
              input: `${item.update_date}`,
              type: "date",
              onBlurInput: (v) => {
                let _item = item;
                _item.update_date = v;
                props.update(_item).then(() => getList(page));
              },
            },

            {
              input: `${item.size}`,
              onBlurInput: (v) => {
                let _item = item;
                _item.size = v;
                props.update(_item).then(() => getList(page));
              },
            },

            {
              input: `${item.delivery_time}`,
              onBlurInput: (v) => {
                let _item = item;
                _item.delivery_time = v;
                props.update(_item).then(() => getList(page));
              },
            },

            {
              input: `${item.text}`,
              onBlurInput: (v) => {
                let _item = item;
                _item.text = v;
                props.update(_item).then(() => getList(page));
              },
            },
            {
              input: `${item.nds}`,
              onBlurInput: (v) => {
                let _item = item;
                _item.nds = v;
                props.update(_item).then(() => getList(page));
              },
            },
          ];

          arr.push(_itemArr);
        });
        setSelectedArr( JSON.stringify( JSON.stringify(list.data)) );
        setTableListOrders(arr);
        props.setLoaderState(false);
      });
  }

  function searchNum(line) {
    if (line.length > 0)
      props.getFilteredUserOrders("deals.id", "=", line, 100);
    else props.getAllUserOrders();
  }
  function searchPhone(line) {
    if (line.length > 0)
      props.getFilteredUserOrders("users.phone", "LIKE", `'%${line}%'`, 100);
    else props.getAllUserOrders();
  }

  return (
    <div id="page" className="home-page">
      <section className="page-info ui-grid">
        <div className="page-info-text">
          <UiPageText
            title={`Заказы: ${
              props.category.selectedCategory
                ? props.category.selectedCategory
                : "ВСЕ"
            }`}
          />
        </div>
 
        <UiButtonColor
          color="indigo"
          text="Загрузить"
          small={true}
          onClick={() => {
            setModalImportActive(true);
          }}
        />
  
        <UiButtonColor
          color="brand"
          icon="load"
          text="Скачать"
          small={true}
          onClick={() => setModalSaveActive(true)}
        />
      </section>
      <section className="page-content">
        <UiTableAdaptive
          titleList={clientsTitles}
          grid={clientsTitles.length}
          tableList={tableListOrders}
          currentPage={page}
          pageCount={maxPages}
          pageSize={50}
          categoriesList={props.storage.categoriesList}
          onChangePage={(p) => getList(p)}
          onChangeSort={(i, a, s) => {
            let _f = JSON.parse(JSON.stringify(filter));
            _f[i] = s;
            setFilter(_f);
            props.setStorageFilter(_f);
            props.setStorageAsc(a);
            getList(0, _f, a);
          }}
        />
      </section>
      {/** modal import */}
      <UiModalImport
        modalActive={modalImportActive}
        modalClose={(f) => {
          if (f) getList(maxPages - 1);
          setModalImportActive(false);
        }}
        category={toArrayClearList(
          props.category.list ? props.category.list : [],
          "title"
        ).reverse()}
        provider={[]}
      />

      {/** modal save */}
      <UiModalSave
        modalActive={modalSaveActive}
        modalClose={() => setModalSaveActive(false)}
        file={false}
      />
    </div>
  );
}

export default connect(
  ({ order, login, category, user, storage, settings }) => ({
    order,
    login,
    category,
    user,
    settings,
    storage,
  }),
  {
    setLoaderState: setLoaderStateAction,

    getCategoryList: getCategoryListAction,
    getStorageListByIdArray: getStorageListByIdArrayAction,
    getStorageList: getStorageListAction,
    setStorageAsc: setStorageAscAction,
    setStorageFilter: setStorageFilterAction,
    getStorageCategoryList: getStorageCategoryListAction,
    update: updateAction,
  }
)(HomePage);
