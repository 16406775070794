import React, { useState } from "react";

import { ReactComponent as SvgSearch } from "../../assets/images/ui/search.svg";

import "./UiInputSearch.scss";

const UiInputSearch = (props) => {
  const [value, setValue] = useState("");
  return (
    <div className="input-search">
      {props.label ? <label>{props.label}</label> : null}
      <div
        className={"input-search-wrap" + (props.xSmall ? " small x-small" : "")}
      >
        <input
          className={"input-text"}
          disabled={props.disabled}
          id={props.id}
          maxLength={props.maxLength ? props.maxLength : 500}
          name={props.name}
          pattern={props.pattern}
          placeholder={props.placeholder}
          type={props.type}
          value={props.value}
          onChange={(e) => {
            props.onChange(e.target.value);
            setValue(e.target.value);
          }}
        />
        <SvgSearch className="input-search-icon" />
      </div>
      {props.error && value === "" ? (
        <p className="input-text-warn">{props.errorText}</p>
      ) : null}
    </div>
  );
};

export default UiInputSearch;
