import React from "react";

import UiIcon from "../icons/UiIcon";

import "./UiButtonColor.scss";

const UiButtonColor = (props) => {
  const styles = {};

  return (
    <div className="button-wrap">
      <button
        className={
          "button" +
          (props.color ? " button-" + props.color : "") +
          (props.radius ? " radius" : "") +
          (props.round ? " round" : "") +
          (props.small ? " small" : "") +
          (props.xSmall ? " small x-small" : "")
        }
        onClick={props.onClick}
        style={styles}
      >
        <span>{props.text}</span>
        {props.icon ? (
          <UiIcon
            fill={props.color !== "white" ? "white" : "dark"}
            icon={props.icon}
            size={props.iconSize ? props.iconSize : 14}
            left
          />
        ) : null}
      </button>
    </div>
  );
};

export default UiButtonColor;
