import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as SvgClose } from "../../assets/images/ui/close.svg";
import { connect } from "react-redux";
import { getXlsFile as getXlsFileAction } from "../../redux/modules/storage";

import { formatDateDMY, formatDateHM } from "../../utilities/date";
import { replaceTags } from "../../utilities/logs";
import { inArrayValueByKey } from "../../utilities/array";

import {
  uploadFile as uploadFileAction,
  getSortedFileStorage as getSortedFileStorageAction,
} from "../../redux/modules/storage";

import UiButtonColor from "../button/UiButtonColor";
import UiIcon from "../icons/UiIcon";
import UiInputText from "../inputs/UiInputText";
import UiModal from "./UiModal";

import "./UiModalImport.scss";
import Env from "../../services/Env";

function UiModalSave(props) {
  const [tab, setTab] = useState(0);
  const [workerSearch, setWorkerSearch] = useState("");

  const [dropArea, setDropArea] = useState("");
  const [isFile, setIsFile] = useState(false);
  const [upFile, setFile] = useState(null);
  const [isFileErr, setIsFileErr] = useState(false);

  /**
   * clicks outside of ref
   */
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * if clicked on outside
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          props.modalClose();
          //alert("You clicked outside of ref!");
        }
      }
      // bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    let _dropArea = document.getElementById("drop_zone");
    if (_dropArea) {
      setDropArea(_dropArea);

      // Prevent default drag behaviors
      ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
        _dropArea.addEventListener(eventName, preventDefaults, false);
        document.body.addEventListener(eventName, preventDefaults, false);
      });

      // Highlight drop area when item is dragged over it
      ["dragenter", "dragover"].forEach((eventName) => {
        _dropArea.addEventListener(eventName, highlight, false);
      });
      ["dragleave", "drop"].forEach((eventName) => {
        _dropArea.addEventListener(eventName, unhighlight, false);
      });

      // Handle dropped files
      _dropArea.addEventListener("drop", handleDrop, false);
    }
  }, [props.modalActive]);


  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function preventDefaults(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  function highlight(e) {
    document.getElementById("drop_zone").classList.add("highlight");
  }

  function unhighlight(e) {
    document.getElementById("drop_zone").classList.remove("active");
  }

  function handleDrop(e) {
    var dt = e.dataTransfer;
    var files = dt.files;
    var f = false;
    console.log(files);
    console.log(files[0].name.includes("xls"), files[0].name.includes("xlsx"));
    if (files[0].name.includes("xlsx")) {
      f = true;
      setFile(files[0]);
    }

    if (f) {
      handleFiles(files);
    } else {
      setIsFileErr(true);
    }
  }

  let uploadProgress = [];

  function initializeProgress(numFiles) {
    document.getElementById("progress-bar").value = 0;
    uploadProgress = [];

    for (let i = numFiles; i > 0; i--) {
      uploadProgress.push(0);
    }
  }

  function handleFiles(files) {
    setIsFile(true);
    files = [...files];
    // initializeProgress(files.length);
    //files.forEach(uploadFile);
  }

  function initializeProgress(numFiles) {
    document.getElementById("progress-bar").value = 0;
    uploadProgress = [];

    for (let i = numFiles; i > 0; i--) {
      uploadProgress.push(0);
    }
  }

  function updateProgress(fileNumber, percent) {
    uploadProgress[fileNumber] = percent;
    let total =
      uploadProgress.reduce((tot, curr) => tot + curr, 0) /
      uploadProgress.length;
    document.getElementById("progress-bar").value = total;
  }

  function uploadFile(file) {
    if(file){
      props.uploadFile(file).then((data) => {
        console.log(data);
        if (data.response) {
          props.getSortedFileStorage({
              file: data.response,
            })
            .then((d) => {
              if (d) window.open(Env.PUBLIC_URL + d, "_blank");
              props.modalClose(true);
            });
        }
      });
    }

  }

  return (
    <UiModal name="modal" show={props.modalActive}>
      <div className="modal-import">
        <div ref={wrapperRef} className="modal-import-wrap">
          {/** close button */}
          <button
            className="modal-deal-info-close"
            onClick={() => props.modalClose()}
          >
            <SvgClose className="modal-deal-info-close-icon" />
          </button>
          <h3>Скачать</h3>
          <UiButtonColor
            color="brand"
            text="Скачать каталог"
            onClick={() => {
              let filter = [];

              props.storage.filter.map((item, index) => {
                if (item) {
                  let _title =
                    index == 0
                      ? "country"
                      : index == 1
                      ? `brand`
                      : index == 2
                      ? `article`
                      : index == 3
                      ? `provider`
                      : index == 4
                      ? `size`
                      : index == 5
                      ? `title`
                      : null;
                  filter.push({ title: _title, value: item });
                }
              });

              props
                .getXlsFile({
                  category: props.category.selectedCategory,
                  sort: filter,
                  asc:  props.storage.filterAsc,
                })
                .then((d) => {
                  if (d) window.open(Env.PUBLIC_URL + d, "_blank");
                });
            }}
          />

          <hr />

          <div className="import-form">
            <label className="import-form-add" id="drop_zone">
              {/** props.file если файл загружен */}
              {isFile ? (
                <div className="import-form-add-wrap">
                  <UiIcon icon="xlsx" size={32} />
                  <p>{upFile.name}</p>
                </div>
              ) : isFileErr ? (
                <p>Поддерживает только xls</p>
              ) : (
                <p>Загрузить файл</p>
              )}

              <input
                type="file"
                style={{ display: "none" }}
                onChange={(e) => {
                  if (e.target.files.length > 0) {
                    if (e.target.files[0].name.includes("xlsx")) {
                      setFile(e.target.files[0]);
                      handleFiles(e.target.files);
                    }
                  }
                }}
              />
            </label>
            {/*
            <div className="import-form-wrap">
              <UiInputText placeholder="Категория" />
            </div>
            <div className="import-form-wrap">
              <UiInputText placeholder="Бренды" />
            </div>
               <div className="import-form-wrap">
              <UiInputText placeholder="Период" />
            </div>
              */}
         

            <div className="import-form-wrap">
              <UiButtonColor color="brand" small text="Скачать" onClick={() => {
                uploadFile(upFile);
              }}/>
            </div>
          </div>
        </div>
      </div>
    </UiModal>
  );
}

export default connect(({ storage, category }) => ({ storage, category }), {
  uploadFile: uploadFileAction,
  getXlsFile: getXlsFileAction,
  getSortedFileStorage: getSortedFileStorageAction,
})(UiModalSave);
