import React from "react";

import UiIcon from "../icons/UiIcon"

import "./UiCardAvatar.scss";

const UiCardAvatar = (props) => {
  return (
    <div
      onClick={props.onClick}
      style={{
        width: props.size,
        height: props.size,
      }}
      className={"card-avatar"}
    >
      {props.avatarText && props.avatarText != null && props.avatarText != "" ?
      <span>{props.avatarText}</span> : 
      <UiIcon icon="user" fill="white" size={24} />
    }
    </div>
  );
};

export default UiCardAvatar;
