import {
    storeData,
    retrieveData,
    safelyParseJSON,
} from "../../services/Storage";
import {
    storeOrder,
    getAllOrders, getStat,
    updateUserOrder, doneUserOrder, getFilteredOrders,
    getOrderComposition, addOrderSpecialComposition,
    editOrderSpecialComposition,
    destroyOrderSpecialComposition, getDashboardUserOrders,
    sendDealSms 
} from "../../services/Order";
import { filter, addWorkerUser } from "../../services/User";

import {
    getAdressAutocompleatKladr
} from "../../services/Adress";
import { inArray } from "../../utilities/array";

import { addFile } from "../../services/Storage";

const moduleName = "order";
const SET_ORDER = `${moduleName}/SET_ORDER`;
const SET_USER_ORDERS = `${moduleName}/SET_USER_ORDERS`;
const SET_ORDERS = `${moduleName}/SET_ORDERS`;
const SET_COMPOSITION = `${moduleName}/SET_COMPOSITION`;
const SET_STAT = `${moduleName}/SET_STAT`;
const SET_ADDRESS = `${moduleName}/SET_ADDRESS`;

const SET_API_REQUEST = `${moduleName}/SET_API_REQUEST`;

const REGISTER = `${moduleName}/REGISTER`;

const defaultState = {
    order: {
        //step1
        address_a: "",
        address_b: "",
        elevator_type_a: 0,
        elevator_type_b: 0,
        address_a_city: null,
        address_b_city: null,

        floor_a: "",
        floor_b: "",
        date: (new Date()),
        time: (new Date()),
        date_dmy: null,
        time_hm: null,
        //step2
        moving_types: 0,
        prr_types: 0,
        volume_car: 0,
        movers: 0,
        additional_work: [],
        tak_value: 0,
        unbuild_value: 0,
        pack_value: 0,
        trash_value: 0,
        photos: [],
        text: "",

        distance: 0,
    },

    ordersUserList: [],
    ordersList: [],
    compositionOrder: [],
    addressList: [],

    statistics: null,
    isFetching: false,
};

export default (state = defaultState, { type, payload }) => {
    switch (type) {
        case SET_ORDER:
            return { ...state, order: payload };
        case SET_USER_ORDERS:
            return { ...state, ordersUserList: payload };
        case SET_ORDERS:
            return { ...state, ordersList: payload };
        case SET_COMPOSITION:
            return { ...state, compositionOrder: payload };
        case SET_STAT:
            return { ...state, statistics: payload };
        case SET_ADDRESS:
            return { ...state, addressList: payload };


        case SET_API_REQUEST:
            return { ...state, isFetching: payload };

        default:
            return state;
    }
};

 
export const setLoaderState = (val) => async (dispatch) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: val
        });
    } catch (error) { 
    }
}


export const findAddress = (_line) => async (dispatch, getState) => {
    try {


        return getAdressAutocompleatKladr(_line).then((res) => {
            //console.log(res.suggestions);

            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            dispatch({
                type: SET_ADDRESS,
                payload: res.suggestions
            });

            return res.suggestions;
        })
    } catch (error) {
        console.error(error);
    }

}

export const getAllUserStat = (_date1, _date2) => async (dispatch, getState) => {
    try {

        let _obj = {
            api_token: getState().login.user.api_token,
            date_start: _date1,
            date_end: _date2,
        }

        return getStat(_obj).then((res) => {
            console.log(res);
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            dispatch({
                type: SET_STAT,
                payload: res.response
            });

            return res.response;
        })
    } catch (error) {
        console.error(error);
    }

}



export const getAllUserOrders = () => async (dispatch, getState) => {
    try {

        let _obj = {
            api_token: getState().login.user.api_token,
            active: true
        }
  
        return getAllOrders(_obj).then((res) => {
            console.log(res);
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            dispatch({
                type: SET_ORDERS,
                payload: res.response
            });

            return res.suggestions;
        })
    } catch (error) {
        console.error(error);
    }

}

export const getDashboardOrders = () => async (dispatch, getState) => {
    try {

 
        return getDashboardUserOrders().then((res) => {
         
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            if (res.status == 200) {
                dispatch({
                    type: SET_ORDERS,
                    payload: res.response
                });
            }


            return res.response;
        })
    } catch (error) {
        console.error(error);
    }

}


export const getFilteredUserOrders = (_key, _sign, _value, _limit) => async (dispatch, getState) => {
    try {

        let _obj = {
            api_token: getState().login.user.api_token,
            filter_key: _key,
            filter_value: _value,
            filter_char: _sign,
            limit: _limit,
        }

        return getFilteredOrders(_obj).then((res) => {
            console.log(res);
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            if (res.status == 200) {
                dispatch({
                    type: SET_ORDERS,
                    payload: res.response
                });
            }


            return res.response;
        })
    } catch (error) {
        console.error(error);
    }

}


export const updateOrder = (_data, _info) => async (dispatch, getState) => {
    try {
        _data['api_token'] = getState().login.user.api_token;
        _data['user_panel_id'] = getState().login.user.id;
        _data['update_info'] = _info;
        if(_data['composition'] == "" || _data['composition'] == null) _data['composition'] = 1;

        return updateUserOrder(_data).then((res) => {
            console.log(res);
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            dispatch(getAllUserOrders());

            return res.suggestions;
        })
    } catch (error) {
        console.error(error);
    }

}

export const doneOrder = (_data) => async (dispatch, getState) => {
    try {
        _data['api_token'] = getState().login.user.api_token;
        _data['user_panel_id'] = getState().login.user.id;

        return doneUserOrder(_data).then((res) => {
            console.log(res);
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            dispatch(getAllUserOrders());

            return res;
        })
    } catch (error) {
        console.error(error);
    }

}
 
export const sendSms = (_data) => async (dispatch, getState) => {
    try {
        _data['api_token'] = getState().login.user.api_token;
        _data['user_panel_id'] = getState().login.user.id;

        return sendDealSms(_data).then((res) => {
 
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
        
            return res;
        })
    } catch (error) {
        console.error(error);
    }

}


export const setOrder = (_val) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_ORDER,
            payload: _val,
        });
        storeData("SET_ORDER", _val);
    } catch (error) {
        console.error(error);
    }
};


export const destroySpecialComposition = (_id) => async (dispatch, getState) => {
    try {
        let _data = { id: _id };
        _data['api_token'] = getState().login.user.api_token;
        return destroyOrderSpecialComposition(_data).then((res) => {
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });

            return res;
        })
    } catch (error) {
        console.error(error);
    }
}

export const addSpecialComposition = (_data) => async (dispatch, getState) => {
    try {
            _data['api_token'] = getState().login.user.api_token;
            return addOrderSpecialComposition(_data).then((res) => {
                dispatch({
                    type: SET_API_REQUEST,
                    payload: false
                });
                if (res.status == 200) {
                   if(res.response.order_id) dispatch(getComposition(res.response.order_id))
                }
                return res;
            })
    } catch (error) {
        console.error(error);
    }
}

export const editSpecialComposition = (_data) => async (dispatch, getState) => {
    try {
            _data['api_token'] = getState().login.user.api_token;
            return editOrderSpecialComposition(_data).then((res) => {
                dispatch({
                    type: SET_API_REQUEST,
                    payload: false
                });
                if (res.status == 200) {
                   if(res.response.order_id) dispatch(getComposition(res.response.order_id))
                }
                return res;
            })
    } catch (error) {
        console.error(error);
    }
}

export const getComposition = (_id) => async (dispatch, getState) => {
    try {
        let _data = { id: _id };

        return getOrderComposition(_data).then((res) => {
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            if (res.status == 200) {
                dispatch({
                    type: SET_COMPOSITION,
                    payload: res.response
                });
            } else {
                dispatch({
                    type: SET_COMPOSITION,
                    payload: []
                });
            }
            return res;
        })
    } catch (error) {
        console.error(error);
    }

}



export const addOrder = (_order) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });

        let order = JSON.parse(JSON.stringify(_order));
        let user_id = null;

        order['api_token'] = getState().login.user.api_token;

        //order['photos'] = order.photos.join(",");
        order['type'] = 0;
        order['from_crm'] = true;
        //order['user_phone'] = order['phone'];
        if(order['composition'] == "") order['composition'] = "1";

        if (order.phone) {
            return filter(getState().login.user.api_token, "phone", order.phone).then((resFilter) => {
                if (resFilter.response) {
                    if (resFilter.response.length > 0) {
                        user_id = resFilter.response[0].id;
                        order['user_id'] = user_id;
                        return storeOrder(order).then((res) => {
                            console.log(res)
                            dispatch({
                                type: SET_API_REQUEST,
                                payload: false
                            });
                            if (res.status == 200) {
                                dispatch(getAllUserOrders());
                            }
                            return res
                        })
                    } else {
                        let _newUserData = {
                            api_token: getState().login.user.api_token,
                            id: getState().login.user.id,
                            is_worker: 0,
                            email: "",
                            comment: "",
                            contact: "",
                            type: 3,
                            name: order.name,
                            phone: order.phone,
                            payment_info: null,
                            fact_contact: null,
                            car: null,
                            car_size: null,
                            car_carrying: null,
                            car_volume: null
                        };

                        return addWorkerUser(_newUserData).then((resUser) => {
                            console.log(resUser);
                            order['user_id'] = resUser.response.id;
                            return storeOrder(order).then((res) => {
                                console.log(res)
                                dispatch({
                                    type: SET_API_REQUEST,
                                    payload: false
                                });
                                if (res.status == 200) {
                                    dispatch(getAllUserOrders());
                                }
                                return res
                            })
                        })
                    }
                }
            })
        }




    } catch (error) {
        console.error(error);
    }

}


export const addPhoto = (_uri) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });

        return addFile(_uri, 0).then((res) => {
            console.log(res)
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });

            return res
        })
    } catch (error) {
        console.error(error);
    }

}

