import React, { useEffect, useRef, useState } from "react";

import { ReactComponent as SvgClose } from "../../assets/images/ui/close.svg";

import UiButtonColor from "../button/UiButtonColor";
import UiDropDown from "../inputs/UiDropDown";
import UiInputText from "../inputs/UiInputText";
import UiModal from "./UiModal";
import UiTextInputMasked from "../inputs/UiTextInputMasked";

import UserConstant from "../../constants/UserConstant";

import Env from "../../services/Env";
import { addFile, convertImages } from "../../services/Storage";

import "./UiModalDeal.scss";
import "./UiModalUser.scss";

import {
  toArrayList,
  toArrayListKey,
  inArrayValueByKey,
} from "../../utilities/array";

function UiModalManager(props) {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [contact, setContact] = useState(null);
  const [comment, setComment] = useState(null);
  const [rating, setRating] = useState(null);
  const [type, setType] = useState(0);

  const [paymentInfo, setPaymentInfo] = useState(null);
  const [fContact, setFContact] = useState(null);
  const [car, setCar] = useState(null);
  const [carSize, setCarSize] = useState(null);
  const [carCarrying, setCarCarrying] = useState(null);
  const [carVolume, setCarVolume] = useState(null);

  const [images, setImages] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [load, setLoad] = useState(false);

  function uploadList(file) {
    setLoad(true);
    addFile(file, 2).then((res) => {
      console.log(res);
      setLoad(false);
      if (res.success) {
        let _arr = images;
        _arr.unshift(res.response);
        setImages(_arr);
        setLoad(true);
        setTimeout(() => setLoad(false), 10);
      }
    });
  }

  useEffect(() => {
    if (props.data != null) {
      setName(props.data.name);
      setPhone(props.data.phone);
      setType(props.data.type);
      setEmail(props.data.email);
      setContact(props.data.contact);
      setImages(convertImages(props.data.images));
      setImageUrl(
        props.data.images ? Env.PUBLIC_URL + props.data.images : null
      );
      setComment(props.data.comment);
      setRating(props.data.rating);
      setPaymentInfo(props.data.payment_info);
      setFContact(props.data.fact_contact);
      setCar(props.data.car);
      setCarSize(props.data.car_size);
      setCarCarrying(props.data.car_carrying);
      setCarVolume(props.data.car_volume);
    } else {
      setName(null);
      setPhone(null);
      setContact(null);
      setEmail(`user${Math.floor(Date.now() / 10000)}`);
      setImages([]);
      setComment(null);
      setImageUrl(null);
      setRating(null);
      setPaymentInfo(null);
      setFContact(null);
      setCar(null);
      setCarSize(null);
      setCarCarrying(null);
      setCarVolume(null);
    }
  }, [props.data]);

  /**
   * clicks outside of ref
   */
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * if clicked on outside
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          props.modalClose();
          //alert("You clicked outside of ref!");
        }
      }
      // bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <UiModal name="modal" show={props.modalActive}>
      <div className="modal-deal">
        <div ref={wrapperRef} className="modal-deal-wrap modal-manager-wrap">
          {/**
           * modal left side - static
           */}
          <div className="modal-deal-about add">
            {/** close button */}
            <button
              className="modal-deal-info-close"
              onClick={() => props.modalClose()}
            >
              <SvgClose className="modal-deal-info-close-icon" />
            </button>
            {/**
             * modal title
             */}
            <div className="modal-deal-about-title">
              <div className="modal-deal-about-title-wrap">
                <h3>
                  {props.data
                    ? "Обновление пользователя"
                    : "Создать пользователя"}
                </h3>
              </div>
              <div className="modal-deal-about-buttons"> </div>
            </div>
            {/**
             * modal notifications + msgs
             */}
            <div className="modal-deal-notes">
              <div className="modal-deal-notes-list-wrap">
                <div className="modal-deal-info">
                  <div className="modal-deal-info-form">
                    <UiInputText
                      label="ФИО"
                      placeholder="Введите ФИО"
                      value={name}
                      onChange={(val) => setName(val)}
                    />
                  </div>
                  <div className="modal-deal-info-form">
                    <UiInputText
                      label="Логин / Email"
                      placeholder="Введите логин"
                      value={email}
                      onChange={(val) => setEmail(val)}
                    />
                  </div>
                  <div className="modal-deal-info-form">
                    <UiDropDown
                      label="Тип пользователя"
                      value={type  }
                      items={toArrayList(UserConstant.USERS_TYPE_LIST)}
                      onChange={(val) => setType(val)}
                    />
                  </div>

                  <div className="modal-deal-info-form">
                    {console.log(props.data )}
                    {props.data ? (
                      <UiButtonColor
                        color="brand"
                        text="Обновить"
                        small={false}
                        onClick={() => {
                          props.onEdit({
                            id: props.data.id,
                            name: name,
                            email: email,
                            phone: phone,
                            type: type,
                            contact: contact,
                            comment: comment,
                            image: images,
                            rating: rating,
                            payment_info: paymentInfo,
                            fact_contact: fContact,
                            car: car,
                            car_size: carSize,
                            car_carrying: carCarrying,
                            car_volume: carVolume,
                            is_worker: type == 4 ? 1 : 0,
                            is_del: 0,
                          });
                        }}
                      />
                    ) : (
                      <UiButtonColor
                        color="brand"
                        text="Создать"
                        small={false}
                        onClick={() => {
                          props.onCreate({
                            name: name,
                            phone: phone,
                            email: email,
                            type: type,
                            contact: contact,
                            comment: comment,
                            image: images,
                            payment_info: paymentInfo,
                            fact_contact: fContact,
                            car: car,
                            car_size: carSize,
                            car_carrying: carCarrying,
                            car_volume: carVolume,
                            is_worker: type == 4 ? 1 : 0,
                          });
                        }}
                      />
                    )}
                  </div>
                  {props.data && (
                    <div className="modal-deal-info-form">
                      <UiButtonColor
                        color="accent"
                        text="Удалить"
                        small={false}
                        onClick={() => {
                          props.onEdit({
                            id: props.data.id,
                            name: name,
                            email: email,
                            phone: phone,
                            type: type,
                            contact: contact,
                            comment: comment,
                            image: images,
                            rating: rating,
                            payment_info: paymentInfo,
                            fact_contact: fContact,
                            car: car,
                            car_size: carSize,
                            car_carrying: carCarrying,
                            car_volume: carVolume,
                            is_worker: type == 4 ? 1 : 0,
                            is_del: 1,
                          });

                          props.modalClose();
                        }}
                      />
                    </div> 
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UiModal>
  );
}

export default UiModalManager;
