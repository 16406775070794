import React, { useEffect, useState }from "react";

import "./UiCheckItem.scss";

import { ReactComponent as SvgIcon } from "../../assets/images/ui/check-white.svg";

 
  const UiCheckItem = (props) => { 

 

  const [radioActive, setRadioActive] = useState(false);

  useEffect(() => {
    setRadioActive(props.defaultValue)
  }, [ props.defaultValue])

  useEffect(() => {
    setRadioActive(props.value)
  }, [ props.value])

  function radioCheck() { 
    props.onChange(!radioActive);
    setRadioActive( !radioActive  );
  }

 
    return (
      <button
        className={"check-item" + (props.xSmall ? " small x-small" : "")}
        onClick={() => radioCheck()}
      >
        <div
          className={
            "check-item-icon" + ( radioActive ? " active" : "")
          }
        >
          <SvgIcon className="check-item-icon-check" />
        </div>
        <span>{props.label}</span>
      </button>
    );
 
}
export default UiCheckItem;
