import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { ReactComponent as SvgClose } from "../../assets/images/ui/close.svg";

import {
  uploadFile as uploadFileAction,
  addStorage as addStorageAction,
} from "../../redux/modules/storage";
import { addCategory as addCategoryAction } from "../../redux/modules/category";

import UiIcon from "../icons/UiIcon";
import UiInputText from "../inputs/UiInputText";
import UiDropDown from "../inputs/UiDropDown";
import UiModal from "./UiModal";

import "./UiModalImport.scss";
import UiButtonColor from "../button/UiButtonColor";
import Env from "../../services/Env";

function UiModalImport(props) {
  const [tab, setTab] = useState(0);
  const [workerSearch, setWorkerSearch] = useState("");

  const [showAddCat, setShowAddCat] = useState(false);
  const [showAddProvider, setShowAddProvider] = useState(false);

  const [dropArea, setDropArea] = useState("");
  const [isFile, setIsFile] = useState(false);
  const [upFile, setFile] = useState(null);
  const [isFileErr, setIsFileErr] = useState(false);

  const [selBrand, setSelBrand] = useState("");
  const [selCategory, setSelCategory] = useState("");
  const [addCatName, setAddCatName] = useState("");

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  /**
   * clicks outside of ref
   */
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * if clicked on outside
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          props.modalClose();
          //alert("You clicked outside of ref!");
        }
      }
      // bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    let _dropArea = document.getElementById("drop_zone");
    if (_dropArea) {
      setDropArea(_dropArea);

      // Prevent default drag behaviors
      ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
        _dropArea.addEventListener(eventName, preventDefaults, false);
        document.body.addEventListener(eventName, preventDefaults, false);
      });

      // Highlight drop area when item is dragged over it
      ["dragenter", "dragover"].forEach((eventName) => {
        _dropArea.addEventListener(eventName, highlight, false);
      });
      ["dragleave", "drop"].forEach((eventName) => {
        _dropArea.addEventListener(eventName, unhighlight, false);
      });

      // Handle dropped files
      _dropArea.addEventListener("drop", handleDrop, false);
    }
  }, [props.modalActive]);

  useEffect(() => {
    if (props.provider.length > 0) setSelBrand(props.provider[0].text);
    if (props.category.length > 0) setSelCategory(props.category[0].text);
  }, [props.category, props.provider]);

  function preventDefaults(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  function highlight(e) {
    document.getElementById("drop_zone").classList.add("highlight");
  }

  function unhighlight(e) {
    document.getElementById("drop_zone").classList.remove("active");
  }

  function handleDrop(e) {
    var dt = e.dataTransfer;
    var files = dt.files;
    var f = false;
    console.log(files);
    console.log(files[0].name.includes("xls"), files[0].name.includes("xlsx"));
    if (files[0].name.includes("xlsx")) {
      f = true;
      setFile(files[0]);
    }

    if (f) {
      handleFiles(files);
    } else {
      setIsFileErr(true);
    }
  }

  let uploadProgress = [];

  function initializeProgress(numFiles) {
    document.getElementById("progress-bar").value = 0;
    uploadProgress = [];

    for (let i = numFiles; i > 0; i--) {
      uploadProgress.push(0);
    }
  }

  function handleFiles(files) {
    setIsFile(true);
    files = [...files];
    // initializeProgress(files.length);
    //files.forEach(uploadFile);
  }

  function initializeProgress(numFiles) {
    document.getElementById("progress-bar").value = 0;
    uploadProgress = [];

    for (let i = numFiles; i > 0; i--) {
      uploadProgress.push(0);
    }
  }

  function updateProgress(fileNumber, percent) {
    uploadProgress[fileNumber] = percent;
    let total =
      uploadProgress.reduce((tot, curr) => tot + curr, 0) /
      uploadProgress.length;
    document.getElementById("progress-bar").value = total;
  }

  function uploadFile(file) {
    console.log(file);
    if (file) {
      props.uploadFile(file).then((data) => {
        if (data?.response) {
          props
            .addStorage({
              provider: selBrand,
              category: selCategory,
              file: data.response,
            })
            .then((res) => {
              if (res.status == 200) {
                setIsFile(false);
                setFile(null);
                props.modalClose(true);
              } else {
              }
            });
        }
      });
    } else {
      alert("Файл не выбран")
    }
  }

  return (
    <UiModal name="modal" show={props.modalActive}>
      <div className="modal-import">
        <div ref={wrapperRef} className="modal-import-wrap">
          {/** close button */}
          <button
            className="modal-deal-info-close"
            onClick={() => props.modalClose()}
          >
            <SvgClose className="modal-deal-info-close-icon" />
          </button>
          <h3>Загрузить</h3>
          <div className="import-form">
            <div className="import-form-wrap">
              <UiDropDown
                placeholder="Категория"
                items={props.category}
                onChange={(v) => {
                  setSelCategory(
                    props.category.filter((item) => {
                      return item.value == v;
                    })[0].text
                  );
                }}
              />

              {!showAddCat && (
                <UiButtonColor
                  color="indigo"
                  round
                  text="+"
                  onClick={() => setShowAddCat(true)}
                />
              )}
              {showAddCat && (
                <UiInputText
                  placeholder="Введите название"
                  onChange={(d) => setAddCatName(d)}
                />
              )}
              {showAddCat && (
                <UiButtonColor
                  color="indigo"
                  small
                  text="Добавить"
                  onClick={() => {
                    props.addCategory({ title: addCatName }).then(() => {
                      setShowAddCat(false);
                    });
                  }}
                />
              )}
            </div>
            <div className="import-form-wrap">
              {/*<UiDropDown 
              placeholder="Поставщик" 
              items={props.provider} 

              onChange={(v) => {
                setSelBrand(props.provider.filter((item) => {return  item.value == v  })[0].text)
              }}
            /> */}
              {/*
              {!showAddProvider && (<UiButtonColor
                color="indigo"
                round
                text="+"
                onClick={() => setShowAddProvider(true)}
              /> )}
              {showAddProvider && (
                <UiInputText placeholder="Введите название" />
              )}
              {showAddProvider && (
                <UiButtonColor color="indigo" small text="Добавить" />
              )}
              */}
            </div>
            <label className="import-form-add" id="drop_zone">
              {/** props.file если файл загружен */}
              {isFile ? (
                <div className="import-form-add-wrap">
                  <UiIcon icon="xlsx" size={32} />
                  <p>{upFile.name}</p>
                </div>
              ) : isFileErr ? (
                <p>Поддерживает только xls</p>
              ) : (
                <p>Загрузить файл</p>
              )}

              <input
                type="file"
                style={{ display: "none" }}
                onChange={(e) => {
                  if (e.target.files.length > 0) {
                    if (e.target.files[0].name.includes("xlsx")) {
                      setFile(e.target.files[0]);
                      handleFiles(e.target.files);
                    }
                  }
                }}
              />
            </label>
          </div>
          <UiButtonColor
            color="indigo"
            text="Добавить в каталог"
            onClick={() => {
              uploadFile(upFile);
            }}
          />
        </div>
      </div>
    </UiModal>
  );
}

export default connect(({ storage }) => ({ storage }), {
  uploadFile: uploadFileAction,
  addStorage: addStorageAction,
  addCategory: addCategoryAction,
})(UiModalImport);
