import ENV from './Env.js';



export const getListByCat = (_id) => {
  return fetch(`${ENV.API_URL}/storage/category/${_id}`, {
    method: 'GET',
    headers: ENV.getHeaders(),
  }).then((response) => {
    return response.json()
  });
}

export const getList = (_page, _cat , _filter) => {
  let str = `${ENV.API_URL}/storage/all?page=${_page}&size=50`;
  if(_cat != null){
    str = `${ENV.API_URL}/storage/all?page=${_page}&size=50&category=${_cat}`
  }
  return fetch(str, {
    method: 'POST',
    headers: ENV.getHeaders(), 
    body: JSON.stringify(_filter)
  }).then((response) => {
    return response.json()
  });
}

export const getCatList = () => {
  return fetch(`${ENV.API_URL}/storage/get/categories`, {
    method: 'GET',
    headers: ENV.getHeaders(), 
  }).then((response) => {
    return response.json()
  });
}

export const getXls = ( _data) => {
  return fetch(`${ENV.API_URL}/storage/generate/xls`, {
    method: 'POST',
    headers: ENV.getHeaders(),
    body: JSON.stringify(_data)
  }).then((response) => {
    return response.json()
  });
}



export const getSortedFile = ( _data) => {
  return fetch(`${ENV.API_URL}/storage/generate/filter/xls`, {
    method: 'POST',
    headers: ENV.getHeaders(),
    body: JSON.stringify(_data)
  }).then((response) => {
    return response.json()
  });
}


export const getListByArray = ( _data) => { 
  return fetch(`${ENV.API_URL}/storage/show/array`, {
    method: 'POST',
    headers: ENV.getHeaders(),
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}


export const makeStorage = (_api, _data) => {
  _data['api_token'] = _api;

  return fetch(`${ENV.API_URL}/storage/store`, {
    method: 'POST',
    headers: ENV.getHeaders(),
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}


export const updateStorage = (_api, _data) => {
  _data['api_token'] = _api;

  return fetch(`${ENV.API_URL}/storage/update/${_data['id']}`, {
    method: 'POST',
    headers: ENV.getHeaders(),
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}

export const getStorageComposition = (_data) => {
  return fetch(`${ENV.API_URL}/composition/storage/show/${_data.id}`, {
      method: 'GET',
      headers: ENV.getHeaders(), 
  }).then(function (response) {
      return response.json();
  });

}

export const getCompositionList = () => {
  return fetch(`${ENV.API_URL}/composition/list/show`, {
      method: 'GET',
      headers: ENV.getHeaders(), 
  }).then(function (response) {
      return response.json();
  });

}