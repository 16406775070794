import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";

import { changeConfirmState as changeConfirmStateAction } from "../../redux/modules/login";
import {
  update as updateAction,
  setCategory as setCategoryAction,
} from "../../redux/modules/category";

import { ReactComponent as SvgAvatar } from "../../assets/images/menu/avatar.svg";
import { ReactComponent as SvgBook } from "../../assets/images/menu/book.svg";
import { ReactComponent as SvgBox } from "../../assets/images/menu/box.svg";
import { ReactComponent as SvgCase } from "../../assets/images/menu/case.svg";
import { ReactComponent as SvgSetting } from "../../assets/images/menu/setting.svg";
import { ReactComponent as SvgStatistic } from "../../assets/images/menu/statistic.svg";
import { ReactComponent as SvgTable } from "../../assets/images/menu/table.svg";

import UiDialog from "../../components/modals/UiDialog";

import UiDropDown from "../inputs/UiDropDown";
import UiButtonColor from "../button/UiButtonColor";

import "./UiMenu.scss";
import UiIcon from "../icons/UiIcon";

function UiMenu(props) {
  const menuConst = [
    {
      icon: "SvgTable",
      title: "",
      location: "/",
    },
    {
      icon: "SvgAvatar",
      title: "",
      location: "/users",
    },
  ];

  const [activeItem, setActiveItem] = useState(0);
  const [menuCatOpen, setMenuCatOpen] = useState(false);
  const [menuObject, setMenuObject] = useState(menuConst);
  const [catList, setCatList] = useState([]);

  const [subMenuOpenedName, setSubMenuOpenedName] = useState(null);

  let location = useLocation();

  useEffect(() => {
    setSubMenuOpenedName(null);
  }, [location]);

  useEffect(() => {
    if (props.category.list) { 
      let arr = props.category.list; 
      setCatList(arr);
    }
  }, [props.category.list]);

  function getIcon(_name) {
    switch (_name) {
      case "SvgAvatar":
        return <SvgAvatar className="menu-left-item-icon" />;
      case "SvgBook":
        return <SvgBook className="menu-left-item-icon" />;
      case "SvgBox":
        return <SvgBox className="menu-left-item-icon" />;
      case "SvgCase":
        return <SvgCase className="menu-left-item-icon" />;
      case "SvgSetting":
        return <SvgSetting className="menu-left-item-icon" />;
      case "SvgStatistic":
        return <SvgStatistic className="menu-left-item-icon" />;
      case "SvgTable":
        return <SvgTable className="menu-left-item-icon" />;
    }
  }

  let menuList = menuObject.map((item, index) => {
    if (item.list) {
      return (
        <button
          key={index}
          className={
            "menu-left-item" +
            (location.pathname === item.location ||
            subMenuOpenedName === item.title
              ? " active"
              : "")
          }
          onClick={() => {
            setSubMenuOpenedName(
              subMenuOpenedName != item.title ? item.title : null
            );
            setActiveItem(index);
          }}
        >
          <div className="menu-left-item-wrap">{getIcon(item.icon)}</div>
        </button>
      );
    } else {
      if( props.login.user?.type === 0 ){
        return (
          <Link
            key={index}
            className={
              "menu-left-item" +
              (location.pathname === item.location ? " active" : "")
            }
            to={item.location}
          >
            <div className="menu-left-item-wrap">{getIcon(item.icon)}</div>
          </Link>
        );
      } else {
        return null;
      }
  
    }
  });

  let categoriesList = catList.map((item, index) => {
    return (
      <div className="menu-categories-item" key={index}>
        <button
          className="menu-categories-item-button"
          onClick={() => {
            props.setCategory( item.title != "Все" ? item.title : '');
            setMenuCatOpen(false)
          }}
        >
          <p>{item.title}</p>
          <p>{item.count}</p>
        </button>
        {item.title != "Все" && (
          <button
            className="menu-categories-item-delete"
            onClick={() =>
              props.changeConfirmState(
                true,
                `Удалить категорию "${item.title}" ?`,
                () => {
                  let t = item;
                  t.is_del = 1;
                  props.update(t);
                }
              )
            }
          >
            <UiIcon fill="red" icon="close" size={8} />
          </button>
        )}
      </div>
    );
  });

  /**
   * clicks outside of ref
   */
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * if clicked on outside
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setMenuCatOpen(false);
          //alert("You clicked outside of ref!");
        }
      }
      // bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div
      id="menu"
      className={"menu-left" + (props.menuActive ? " active" : "")}
    >
      {props.categoriesActive ? (
        <div className="menu-categories">
          <UiButtonColor
            color="brand"
            onClick={() => setMenuCatOpen(!menuCatOpen)}
            small
            text="Категории"
          />
          {menuCatOpen && (
            <div ref={wrapperRef} className="menu-categories-dropdown">
              <div className="menu-categories-title">
                <h3>Категорий:</h3>
                <p>{categoriesList.length}</p>
              </div>
              <div className="menu-categories-list">{categoriesList}</div>
            </div>
          )}
        </div>
      ) : (
        <div className="menu-categories"></div>
      )}
      <div className="menu-left-list">{menuList}</div>
    </div>
  );
}

export default connect(({ category, login }) => ({ category, login }), {
  changeConfirmState: changeConfirmStateAction,
  update: updateAction,
  setCategory: setCategoryAction,
})(UiMenu);
