import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getManagersList as getManagersListAction,
  addManager as addManagerAction,
  editWorker as editWorkerAction,
} from "../../redux/modules/users";
import { formatDateDMY, formatDateHM } from "../../utilities/date";
import { convertImages } from "../../services/Storage";

import UsersConstant from "../../constants/UserConstant";

import UiInputSearch from "../../components/inputs/UiInputSearch";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiModalManager from "../../components/modals/UiModalManager";
import UiPageText from "../../components/text/UiPageText";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";

import "./ManagersStyle.scss";

function ManagersPage(props) {
  const [searchLine, setSearchLine] = useState(null);
  const clientsTitles = [
    { sort: true, title: "Тип пользователя",  },
    { sort: true, title: "ФИО",  },
    { sort: true, title: "Логин" },
    { sort: true, title: "Пароль" },
    { sort: false, title: "Редактирование",  },
  ];
  const [tableListUsers, setTableListUsers] = useState([]);

  const [modalActive, setModalActive] = useState(false);
  const [itemData, setItemData] = useState(null);

  useEffect(() => {
    if (props.login.user){
      props.getManagersList();
      if(props.login.user.type !== 0){
        window.location.href = "/";
      }
    } 
  }, [props.login.user]);

  useEffect(() => {
    var arr = [];
    props.users.usersList.map((item, index) => {
      arr.push([
        {
          title: clientsTitles[1].title,
          text: item.type === 0 ? "Администратор" : "Менеджер",
        },
        {
          title: clientsTitles[1].title,
          text: item.name,
        },
        {
          title: clientsTitles[2].title,
          text: item.email,
        },
        {
          title: clientsTitles[3].title,
          text: props.login.user.type === 0 ? item.password : " *** ",
        },
        {
          button: "edit",
          onClick: () => {
            setItemData(item);
            setModalActive(true)
          },
        },
      ]);
    });
    setTableListUsers(arr);
  }, [props.users.usersList]);

  return (
    <div id="page" className="managers-page">
      <section className="page-info ui-grid">
        <div className="page-info-text">
          <UiPageText title="Менеджеры" />
        </div>
        <div className="page-info-button">
          <UiButtonColor
            color="indigo"
            text="Добавить"
            small={true}
            onClick={() => setModalActive(true)}
          />
        </div>
      </section>
      <section className="page-content">
        <UiTableAdaptive
          titleList={clientsTitles}
          grid={clientsTitles.length}
          tableList={tableListUsers}
          search={searchLine}
          disableFilter={true}
        />
      </section>

      {/* modal */}
      <UiModalManager
        modalActive={modalActive}
        modalClose={() => setModalActive(false)}
        data={itemData}
        onCreate={(data) => {
          props.addManager(data).then((res) => {
            if (res) {
              setModalActive(false);
              props.getManagersList();
            }
          });
        }}
        onEdit={(data) => {
          props.editWorker(data).then((res) => {
            if (res) {
              setModalActive(false);
              props.getManagersList();
            }
          });
        }}
      />
    </div>
  );
}

export default connect(({ login, users }) => ({ login, users }), {
  getManagersList: getManagersListAction,
  addManager: addManagerAction,
  editWorker: editWorkerAction,
})(ManagersPage);
