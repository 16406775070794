import Env from "../../services/Env";
import {
  getList,
  getListByCat,
  makeStorage,
  updateStorage,
  getListByArray,
  getCatList,
  getXls,
  getSortedFile
} from "../../services/BaseStorage";
import { addFile } from "../../services/Storage";
import { safelyParseJSON } from "../../services/Storage";

const moduleName = "storage";

const SET_LIST = `${moduleName}/SET_LIST`;
const SET_FILTER = `${moduleName}/SET_FILTER`;
const SET_FILTER_ASC = `${moduleName}/SET_FILTER_ASC`;
const SET_CAT_LIST = `${moduleName}/SET_CAT_LIST`;
const SET_COMPOSITION_LIST = `${moduleName}/SET_COMPOSITION_LIST`;
const SET_ALTERNATIVE_LIST = `${moduleName}/SET_ALTERNATIVE_LIST`;
const SET_ERROR = `${moduleName}/SET_ERROR`;
const SET_ERROR_TYPE = `${moduleName}/SET_ERROR_TYPE`;
const SET_API_REQUEST = `${moduleName}/SET_API_REQUEST`;

const defaultState = {
  list: [],
  filter: [],
  categoriesList: [],
  compositionList: [],
  alternativeList: [],
  filterAsc: "asc",
  error: false,
  isFetching: false,
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case SET_LIST:
      return { ...state, list: payload };
    case SET_FILTER:
      return { ...state, filter: payload };
      case SET_FILTER_ASC:
      return { ...state, filterAsc: payload };
      
    case SET_ERROR:
      return { ...state, error: payload };
    case SET_CAT_LIST:
      return { ...state, categoriesList: payload };
    case SET_COMPOSITION_LIST:
      return { ...state, compositionList: payload };
    case SET_ALTERNATIVE_LIST:
      return { ...state, alternativeList: payload };
    case SET_ERROR_TYPE:
      return { ...state, errorType: payload };

    case SET_API_REQUEST:
      console.log("fetching API request", payload);
      return { ...state, isFetching: payload };

    default:
      return state;
  }
};

export const setList = (value) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LIST,
      payload: value,
    });
  } catch (error) {
    console.error(error);
  }
};

export const setStorageFilter = (value) => async (dispatch) => {
  try {
    dispatch({
      type: SET_FILTER,
      payload: value,
    });
  } catch (error) {
    console.error(error);
  }
};

export const setStorageAsc = (value) => async (dispatch) => {
  try {
    dispatch({
      type: SET_FILTER_ASC,
      payload: value,
    });
  } catch (error) {
    console.error(error);
  }
};



export const getStorageList = (_page, _category, _asc, _filter) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });

    let filter = [];
   

    _filter.map((item,index) => {
      if(item && item.length > 0){
        let _title = index == 0 ? 'country':
        index  == 1 ?        `brand` :
        index  == 2   ?      `article` :
        index  == 3  ?  `provider` :
        index  == 4  ?   `size` : 
        index  == 5  ? `title` :  
        null ;
        filter.push({ title: _title, value: item })
      } 
    })

    return getList(_page, _category, 
      {
        asc: _asc,
        sort: filter,
        category: _category,
        page: _page , 
      }
    ).then((res) => {
      if (res.response) {
        let arr = res.response;

        dispatch({
          type: SET_LIST,
          payload: arr,
        });
      }
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });
      return res.response;
    });
  } catch (error) {
    console.error(error);
  }
};
 

export const getStorageCategoryList = () => async (dispatch, getState) => {
    try {
      dispatch({
        type: SET_API_REQUEST,
        payload: true,
      });
      let arr = [];
      return getCatList().then((res) => {
        if (res.response) {
         
          res.response.map((item) => {
            let m = []; 
            item.map((it) => {
               if(Object.values(it)[0] != "" && Object.values(it)[0] != null) m.push( Object.values(it)[0] ); 
            }); 
            arr.push(m);
          }) 
          
    

          dispatch({
            type: SET_CAT_LIST,
            payload: arr,
          });
        }
        dispatch({
          type: SET_API_REQUEST,
          payload: false,
        });
        return arr;
      });
    } catch (error) {
      console.error(error);
    }
  };



export const getXlsFile = (_data) => async (dispatch, getState) => {
  try {
    return getXls(_data).then((res) => {
      return res.response;
    });
  } catch (error) {
    console.error(error);
  }
};

export const getStorageListByCat = (_id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });

    return getListByCat(_id).then((res) => {
      console.log(res);
      if (res.response) {
        let arr = res.response;
        arr.map((item) => {
          item.composition = safelyParseJSON(item.composition);
        });
        dispatch({
          type: SET_LIST,
          payload: arr,
        });
      }
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });
      return res.response;
    });
  } catch (error) {
    console.error(error);
  }
};

export const getStorageListByIdArray = (_arr) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });

    let _data = {
      api_token: getState().login.user.api_token,
      id: _arr.split(","),
    };
    return getListByArray(_data).then((res) => {
      if (res.response) {
        dispatch({
          type: SET_COMPOSITION_LIST,
          payload: res.response,
        });
      }
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });
      return res.response;
    });
  } catch (error) {
    console.error(error);
  }
};

export const addStorage = (_data) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });

    return makeStorage(getState().login.user.api_token, _data).then((res) => {
      console.log(res);
      if (res.response) {
        dispatch(getStorageList(1, _data.category, "asc", []));
      }
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });
      return res;
    });
  } catch (error) {
    console.error(error);
  }
};

export const getSortedFileStorage = (_data) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });

    return getSortedFile(  _data).then((res) => {
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });

       return res.response;
    }).catch(() => {
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });
    })
  } catch (error) {
    console.error(error);
  }
};

export const update = (_data) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });

    return updateStorage(getState().login.user.api_token, _data).then((res) => { 
      if (res.response) {
        //dispatch(getStorageList());
      }
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });
      return res;
    });
  } catch (error) {
    console.error(error);
  }
};

export const uploadFile = (_uri) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });

    return addFile(_uri, 0).then((res) => {
      console.log(res);
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });

      return res;
    }).catch(() => {
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });
    })
  } catch (error) {
    console.error(error);
  }
};
