import React, { useState } from "react";

import "./UiInputText.scss";

const UiInputText = (props) => {
  const [value, setValue] = useState("");
  return (
    <div className="input-wrap">
      {props.label ? <label>{props.label}</label> : null}
      <input
        className={
          "input-text" +
          (props.disabled ? " disabled" : "") +
          (props.info ? " info-active" : "") +
          (props.small ? " small" : "")
        }
        disabled={props.disabled}
        id={props.id}
        maxLength={props.maxLength ? props.maxLength : 500}
        name={props.name}
        pattern={props.pattern}
        placeholder={props.placeholder}
        type={props.type}
        value={props.value}
        onBlur={(e) => { props && props.onBlur && props.onBlur(e.target.value)}}
        onChange={(e) => {
          if( props.onChange ) {
            let val =  e.target.value ;
            if(props.type == "phone")  val = val.toString().replace(/^\8/, "7").replace(/[^0-9]/g, '');
            if(props.type == "number")   val = parseFloat( val );
            if(props.textOnly)   val = val.replace(/[^a-zA-Z ]/g, "");
            if(props.upperCase)   val = val.toUpperCase();
            if(props.type != "file") props.onChange( val ); else props.onChange( e ); 
            setValue(val);
          }

        }}
        onFocus={() => props &&  props.onFocus &&  props.onFocus()}
      />
      {props.info ? (
        <div className="input-text-info-wrap">
          <p className="input-text-info">{props.info}</p>
        </div>
      ) : null}
      {props.error  ? (
        <p className="input-text-error">{props.errorText}</p>
      ) : null}
    </div>
  );
};

export default UiInputText;
