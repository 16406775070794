import React from "react";
import { connect } from "react-redux";
import { changeConfirmState as changeConfirmStateAction } from "../../redux/modules/login";

import { ReactComponent as SvgInfo } from "../../assets/images/ui/info.svg";

import UiButtonColor from "../button/UiButtonColor";
import UiModal from "./UiModal";

import "./UiModal.scss";

function UiDialog(props) {
  return (
    <UiModal name="alert" show={props.login.confirmShowed}>
      <div className="alert-card">
        <SvgInfo className="dialog-icon" />
        <div className="alert-card-title">
          <h3>{props.login.alertTitle}</h3>
        </div>

        <div className="dialog-text">
          <p>{props.login.alertText}</p>
        </div>
        <div className="dialog-buttons ui-grid">
          <UiButtonColor
            color="brand"
            onClick={() => {
              props.login.confirmFunc();
              props.changeConfirmState(false);
            }}
            text={"Подтвердить"}
            small
          />
          <UiButtonColor
            color="accent"
            onClick={() => props.changeConfirmState(false)}
            text={"Отмена"}
            small
          />
        </div>
      </div>
    </UiModal>
  );
}

export default connect(({ login }) => ({ login }), {
  changeConfirmState: changeConfirmStateAction,
})(UiDialog);
