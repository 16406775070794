import React from "react";
import { Routes, Route } from "react-router-dom";

import ProtectedRoute from "./routes/ProtectedRoute";
import ProtectedOrgRoute from "./routes/ProtectedOrgRoute";

import LoginPage from "../pages/login/LoginPage";
import HomePage from "../pages/home/HomePage";
import ManagersPage from "../pages/managers/ManagersPage";

function AppNavigator() {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/users"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <ManagersPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

export default AppNavigator;
