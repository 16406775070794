import React, { useEffect, useRef, useState } from "react";

import { ReactComponent as SvgAngle } from "../../assets/images/ui/arrow-sort.svg";

import { ReactComponent as SvgDelete } from "../../assets/images/ui/delete.svg";

import Env from "../../services/Env";

import "./UiTableAdaptiveTitle.scss";
import UiButtonColor from "../button/UiButtonColor";
import UiSelect from "../select/UiSelect";
import UiInputSearch from "../inputs/UiInputSearch";
import UiCheckItem from "../check/UiCheckItem";


import { unCheckInArray, inArrayValue } from "../../utilities/array";

const UiTableAdaptiveTitleCol = (props) => {
  const [sortOpen, setSortOpen] = useState(false);
  const [asc, setAsc] = useState("asc");
  const [filter, setFilter] = useState([]);
  const [search, setSearch] = useState("");

  /**
   * clicks outside of ref
   */
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * if clicked on outside
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setSortOpen(false);
          //alert("You clicked outside of ref!");
        }
      }
      // bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <td
      className={
        "table-card-col" +
        (props.color === "blue" ? " blue" : "") +
        (props.color === "red" ? " red" : "") +
        (props.strong ? " strong" : "")
      }
    >
      <div
        className="table-card-col-inner"
        onClick={() => {
          if (!props.disableFilter) {
            if (props.sort) {
              setSortOpen(!sortOpen);
            }
          }
          if( props.onDelete ) props.onDelete();
        }}
      >
        {props.delete ?  <SvgDelete className="table-card-button-icon" style={{width: "30px", zIndex: 999 }}  />   : null}

        {props.titles ? (
          <p
            className={
              "table-card-title" + (filter.length > 0 ? " active" : "")
            }
            onClick={props.textLink ? props.linkClick : null}
          >
            {props.title}
          </p>
        ) : null}
        {props.sort ? <SvgAngle className="table-card-sort" /> : null}
      </div>
      {props.sort ? (
        <div
          ref={wrapperRef}
          className={"table-sort-dropdown" + (sortOpen ? " open" : "")}
        >
          <h4>{props.title}</h4>
          <p>Сортировка</p>
          <div className="table-sort-dropdown-sort">
            <UiButtonColor
              color={asc == "asc" ? "dark" : "white"}
              icon="sort-az"
              iconSize={10}
              text="По возрастанию"
              xSmall
              onClick={() => {
                setAsc("asc");
              }}
            />
            <UiButtonColor
              color={asc == "desc" ? "dark" : "white"}
              icon="sort-za"
              iconSize={10}
              text="По убыванию"
              xSmall
              onClick={() => {
                setAsc("desc");
              }}
            />
          </div>
          {/*
            <div className="table-sort-dropdown-color">
              <p>По цвету:</p>
              <UiSelect optionList={[]} xSmall />
            </div>
          */}
          <div className="table-sort-dropdown-search">
            <UiInputSearch
              placeholder="Поиск"
              xSmall
              onChange={(line) => {
                setSearch(line);
              }}
            />
            <div className="table-sort-dropdown-search-scroll">
              {(props.categoriesList
                ? props.categoriesList[
                    props.title == "Страна"
                      ? 0
                      : props.title == "Бренд"
                      ? 1
                      : props.title == "Артикул"
                      ? 2
                      : props.title == "Поставщик"
                      ? 3
                      : props.title == "Габариты"
                      ? 4
                      : props.title == "Наименование"
                      ? 5
                      : 0
                  ]
                  ? props.categoriesList[
                      props.title == "Страна"
                        ? 0
                        : props.title == "Бренд"
                        ? 1
                        : props.title == "Артикул"
                        ? 2
                        : props.title == "Поставщик"
                        ? 3
                        : props.title == "Габариты"
                        ? 4
                        : props.title == "Наименование"
                        ? 5
                        : 0
                    ]
                  : []
                : []
              )
                .filter((item) => {
                  if (search == "" || item.includes(search.toLowerCase()))
                    return item;
                })
                .map((item, index) => {
                  return (
                    <UiCheckItem
                      key={index}
                      label={item}
                      value={inArrayValue(filter, item) == item}
                      onChange={() => {
                        let arr = unCheckInArray(filter, item);

                        setFilter(JSON.parse(JSON.stringify(arr)));
                      }}
                      xSmall
                    />
                  );
                })}
            </div>
          </div>
          <div className="table-sort-dropdown-btns">
            <UiButtonColor
              color="brand"
              text="Применить фильтр"
              xSmall
              onClick={() => {
                let index =
                  props.title == "Страна"
                    ? 0
                    : props.title == "Бренд"
                    ? 1
                    : props.title == "Артикул"
                    ? 2
                    : props.title == "Поставщик"
                    ? 3
                    : props.title == "Габариты"
                    ? 4
                    : props.title == "Наименование"
                    ? 5
                    : null;
                props.onChangeSort(index, asc, filter);
                setSortOpen(false);
              }}
            />
            <UiButtonColor
              color="white"
              text="Очистить фильтр"
              xSmall
              onClick={() => {
                setFilter(JSON.parse(JSON.stringify([])));
                let index =
                  props.title == "Страна"
                    ? 0
                    : props.title == "Бренд"
                    ? 1
                    : props.title == "Артикул"
                    ? 2
                    : props.title == "Поставщик"
                    ? 3
                    : props.title == "Габариты"
                    ? 4
                    : props.title == "Наименование"
                    ? 5
                    : null;

                props.onChangeSort(index, asc, []);
                setSortOpen(false);
              }}
            />
          </div>
        </div>
      ) : null}
    </td>
  );
};

const UiTableAdaptiveTitle = (props) => {
  if (props.cols) {
    if (props.cols.length > 0) {
      var cols = props.cols.map((item, index) => {
        return (
          <UiTableAdaptiveTitleCol
            key={index}
            color={item.color}
            linkClick={item.linkClick}
            sort={item.sort}
            strong={item.strong}
            textLink={item.textLink}
            title={props.titles[index].title}
            
            delete={props.titles[index].delete}
            onDelete={props.titles[index].onDelete}

            titles={props.titles}
            categoriesList={props.categoriesList}
            disableFilter={props.disableFilter}
            onChangeSort={(i, a, s) => props.onChangeSort(i, a, s)}
          />
        );
      });
    }
  }

  return (
    <tr
      className={
        "table-card" +
        (!props.adaptive ? " static" : "") +
        (props.isTitle ? " title" : "") +
        (props.grid ? " grid-" + props.grid : "") +
        (props.colored ? " colored" : "") +
        (props.color === "blue"
          ? " blue"
          : props.color === "green"
          ? " green"
          : props.color === "yellow"
          ? " yellow"
          : props.color === "white"
          ? " white"
          : props.color === "violet"
          ? " violet"
          : "")
      }
    >
      {props.cols ? cols : "-"}
    </tr>
  );
};

export default UiTableAdaptiveTitle;
