import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import UiButtonColor from "../../components/button/UiButtonColor";
import UiInputText from "../../components/inputs/UiInputText";
import UiHeaderBg from "../../components/header/UiHeaderBg";
import UiText from "../../components/text/UiText";
import UiTextTitle from "../../components/text/UiTextTitle";

import { loginUser as loginUserAction } from "../../redux/modules/login";

import "./LoginStyle.scss";

function LoginPage(props) {
  const navigate = useNavigate();
  const [keyboardActive, setKeyboardActive] = useState(false);
  const [email, setEmail] = useState(null);
  const [pass, setPass] = useState(null);
  const [err, setErr] = useState(false);

  useEffect(() => {
    if (props.login.user) {
      navigate("../", { replace: true });
    }
  }, [props.login.user]);

  function login() {
    setErr(false);
    if (email && pass) {
      props.loginUser(email, pass).then((res) => { 
        if(res === null){
          console.log("SSS", res)
          setErr(true);
        } 
      });
    }
  }

  return (
    <div id="page" className="login-page">
      <div className="form">
        <div className="form-inner">
          <div className="login-title-wrap">
            <UiTextTitle
              adaptive
              color="brand"
              size={32}
              text="Добро пожаловать"
            />
            <UiText adaptive color="dark" size="f22">
              Вход в панель управления
            </UiText>
          </div>
          <div className="login-input-wrap">
            <UiInputText
              onBlur={() => setKeyboardActive(false)}
              onChange={(val) => setEmail(val)}
              onFocus={() => setKeyboardActive(true)}
              error={false}
              errorText="Неверный логин"
              name="username"
              placeholder="Логин"
              type="text"
            />
          </div>
          <div className="login-input-wrap">
            <UiInputText
              onBlur={() => {
                setKeyboardActive(false);
                setErr(false);
              } }
              onChange={(val) => setPass(val)}
              onFocus={() => setKeyboardActive(true)}
              error={err}
              errorText="Неверный пароль"
              name="password"
              placeholder="Пароль"
              type="password"
            />
          </div>
          <div className="login-button-wrap">
            <UiButtonColor
              color="brand"
              
              onClick={() => login()}
              text="Войти"
            />
          </div>
        </div>
      </div>
      <UiHeaderBg keyboardActive={keyboardActive} />
    </div>
  );
}

export default connect(({ login }) => ({ login }), {
  loginUser: loginUserAction,
})(LoginPage);
